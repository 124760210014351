<template>
  <b-card
    class="address-card"
    :header="formatHeader(attributeName)"
    header-border-variant="white"
    header-bg-variant="white"
  >
    <b-card-text class="address-card-text">
      <p>{{ address.line1 }}</p>
      <p v-if="address.line2">
        {{ address.line2 }}
      </p>
      <p>
        {{ address.city }}, {{ address.state_province_region }}
      </p>
      <p>
        {{ address.zip_postal_code }}
      </p>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DetailAddressCard',
  props: {
    attributeName: String,
    detail: [Object, String], // could be string 'REGISTERED_AGENT_ADDRESS'
  },
  data() {
    return {
      address: this.checkAddress(this.detail),
    }
  },
  methods: {
    ...mapGetters('account', ['activeAccountServices']),
    formatHeader(attributeName) {
      return attributeName
        .split('_')
        .map((str) =>  str.charAt(0).toUpperCase() + str.slice(1) )
        .join(' ')
    },
    checkAddress(detail) {
      if (detail === 'REGISTERED_AGENT_ADDRESS') {
        return this.fetchRaAddress()
      } else {
        return detail
      }
    },
    fetchRaAddress() {
      const raInfo = this.activeAccountServices().find(service => service.type === 'registered-agent')
      if (!raInfo) return null
      return raInfo.registered_agent_address
    },
  },
}
</script>

<style lang="scss" scoped>
.address-card {
  height: 100%;
  font-size: 0.875em;
  color: #7C7C7C;

  .address-card-text {
    font-size: 1.0em;
    color: #363636;

    p {
      display: inline;
    }
  }
}
</style>
