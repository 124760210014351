var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "address-card",
      attrs: {
        header: _vm.formatHeader(_vm.attributeName),
        "header-border-variant": "white",
        "header-bg-variant": "white",
      },
    },
    [
      _c("b-card-text", { staticClass: "address-card-text" }, [
        _c("p", [_vm._v(_vm._s(_vm.address.line1))]),
        _vm.address.line2
          ? _c("p", [_vm._v("\n      " + _vm._s(_vm.address.line2) + "\n    ")])
          : _vm._e(),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.address.city) +
              ", " +
              _vm._s(_vm.address.state_province_region) +
              "\n    "
          ),
        ]),
        _c("p", [
          _vm._v("\n      " + _vm._s(_vm.address.zip_postal_code) + "\n    "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }